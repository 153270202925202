import { D1Database } from '@cloudflare/workers-types';

export const names = [
  'Livio',
  'Geoff',
  'Leon',
  'Carter',
  'Ivan',
  'James',
  'Tyler',
  'Paul',
  'Elliot',
  'Antti',
  'Diarmaid',
];

export interface AppConfig {
  d1Database: D1Database;
}

// This function should be called with the actual D1 database instance
// when initializing your application
export function createConfig(d1Database: D1Database): AppConfig {
  return {
    d1Database,
  };
}