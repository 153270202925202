import React, { useState, useEffect } from 'react';
import { names } from '../config';

interface UserIdentificationProps {
  onUserSelected: (user: string) => void;
}

const UserIdentification: React.FC<UserIdentificationProps> = ({ onUserSelected }) => {
  const [selectedUser, setSelectedUser] = useState<string | null>(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('selectedUser');
    if (storedUser) {
      setSelectedUser(storedUser);
      onUserSelected(storedUser);
    }
  }, [onUserSelected]);

  const handleUserSelect = (user: string) => {
    setSelectedUser(user);
    localStorage.setItem('selectedUser', user);
    onUserSelected(user);
  };

  if (selectedUser) {
    return (
      <div className="mb-4">
        <p>Logged in as: {selectedUser}</p>
        <button
          onClick={() => handleUserSelect('')}
          className="mt-2 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
        >
          Change User
        </button>
      </div>
    );
  }

  return (
    <div className="mb-4">
      <h2 className="text-lg font-semibold mb-2">Select Your Name</h2>
      <div className="grid grid-cols-2 gap-2">
        {names.map((name) => (
          <button
            key={name}
            onClick={() => handleUserSelect(name)}
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
          >
            {name}
          </button>
        ))}
      </div>
    </div>
  );
};

export default UserIdentification;