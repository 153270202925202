import React, { useState, useEffect, useCallback } from 'react';
import UserIdentification from './components/UserIdentification';
import CallPartnerSelection from './components/CallPartnerSelection';
import MonthlyCallSummary from './components/MonthlyCallSummary';
import Modal from './components/Modal';
import { CallMatrix, getCallMatrix, logCall } from './api/accountabilityApi';

const App: React.FC = () => {
  const [matrixData, setMatrixData] = useState<CallMatrix>({});
  const [currentUser, setCurrentUser] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [selectedPartner, setSelectedPartner] = useState<string | null>(null);

  const fetchMatrixData = useCallback(async () => {
    try {
      const data = await getCallMatrix();
      setMatrixData(data);
      setLoading(false);
    } catch (error) {
      console.error('Failed to fetch call matrix data:', error);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchMatrixData();
  }, [fetchMatrixData]);

  const handleUserSelected = (user: string) => {
    setCurrentUser(user);
  };

  const handlePartnerSelected = (partner: string) => {
    setSelectedPartner(partner);
    setShowModal(true);
  };

  const handleConfirmCall = async () => {
    if (!currentUser || !selectedPartner) return;

    try {
      await logCall({ participant_a: currentUser, participant_b: selectedPartner });
      // Refresh the matrix data after logging a call
      await fetchMatrixData();
      setShowModal(false);
      setSelectedPartner(null);
    } catch (error) {
      console.error('Failed to log call:', error);
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="py-4 sm:py-6">
        <header className="mb-4">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <h1 className="text-xl sm:text-2xl font-bold leading-tight text-gray-900">BIAD Accountability Matrix</h1>
          </div>
        </header>
        <main className="mt-4 sm:mt-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
            <UserIdentification onUserSelected={handleUserSelected} />
            {currentUser && (
              <>
                <div className="bg-blue-100 border-l-4 border-blue-500 text-blue-700 p-4 mb-6" role="alert">
                  <p className="font-bold">Important:</p>
                  <p>Only one person needs to log the call. Double-logging is not necessary.</p>
                </div>
                <CallPartnerSelection
                  currentUser={currentUser}
                  onPartnerSelected={handlePartnerSelected}
                />
              </>
            )}
            <div className="mt-8">
              <MonthlyCallSummary matrixData={matrixData} />
            </div>
          </div>
        </main>
      </div>
      <Modal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={handleConfirmCall}
        title="Confirm Call Log"
        message={`Are you sure you want to log a call with ${selectedPartner}?`}
      />
    </div>
  );
}

export default App;
