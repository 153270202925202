import React from 'react';
import { names } from '../config';
import { CallMatrix } from '../api/accountabilityApi';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

interface CallData {
  person: string;
  weeklyCallCount: number;
  monthlyCallCount: number;
}

interface MonthlyCallSummaryProps {
  matrixData: CallMatrix;
}

const MonthlyCallSummary: React.FC<MonthlyCallSummaryProps> = ({ matrixData }) => {
  console.log('Raw matrixData:', JSON.stringify(matrixData, null, 2));

  const calculateCallData = (matrixData: CallMatrix): CallData[] => {
    const callData: CallData[] = names.map(person => ({ person, weeklyCallCount: 0, monthlyCallCount: 0 }));

    const now = new Date();
    const aestOffset = 10 * 60 * 60 * 1000; // AEST is UTC+10
    const aestNow = new Date(now.getTime() + aestOffset);
    
    // Find the most recent Monday 7pm AEST
    const daysSinceMonday = (aestNow.getUTCDay() + 6) % 7; // Days since last Monday
    const hoursSinceMondayEvening = (aestNow.getUTCHours() + 24 - 19) % 24; // Hours since 7pm
    const minutesSinceMondayEvening = aestNow.getUTCMinutes();
    const secondsSinceMondayEvening = aestNow.getUTCSeconds();
    const msSinceWeekStart = (daysSinceMonday * 24 * 60 * 60 * 1000) + 
                             (hoursSinceMondayEvening * 60 * 60 * 1000) + 
                             (minutesSinceMondayEvening * 60 * 1000) +
                             (secondsSinceMondayEvening * 1000);
    const startOfWeek = new Date(aestNow.getTime() - msSinceWeekStart);
    startOfWeek.setUTCHours(9, 0, 0, 0); // Set to 7pm AEST (9am UTC)
    const startOfMonth = new Date(Date.UTC(aestNow.getUTCFullYear(), aestNow.getUTCMonth(), 1, 9, 0, 0, 0)); // Set to 7pm AEST on the 1st of the month

    console.log('Start of week:', startOfWeek.toISOString());
    console.log('Start of month:', startOfMonth.toISOString());

    const processCall = (callTimestamp: string, indexA: number) => {
      const callDate = new Date(callTimestamp);
      if (callDate >= startOfMonth) {
        callData[indexA].monthlyCallCount++;
        if (callDate >= startOfWeek) {
          callData[indexA].weeklyCallCount++;
        }
      }
    };

    for (const participant_a in matrixData) {
      const indexA = names.indexOf(participant_a);
      if (indexA !== -1) {
        for (const participant_b in matrixData[participant_a]) {
          const calls = matrixData[participant_a][participant_b];
          if (Array.isArray(calls)) {
            calls.forEach(callTimestamp => processCall(callTimestamp, indexA));
          } else if (typeof calls === 'number') {
            // Handle old data format
            callData[indexA].monthlyCallCount += calls;
            callData[indexA].weeklyCallCount += calls; // Assume all calls are within the week for old data
          }
        }
      }
    }

    console.log('Processed call data:', callData);

    return callData.sort((a, b) => b.monthlyCallCount - a.monthlyCallCount);
  };

  const callData = calculateCallData(matrixData);

  console.log('Final call data for chart:', callData);

  return (
    <div className="px-2 sm:px-4 lg:px-6 mt-8">
      <h2 className="text-lg font-semibold mb-4">Call Summary</h2>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={callData}
          margin={{
            top: 20,
            right: 10,
            left: 0,
            bottom: 60,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis 
            dataKey="person" 
            interval={0}
            tick={({ x, y, payload }) => (
              <text 
                x={x} 
                y={y} 
                dy={16} 
                textAnchor="end" 
                fill="#666"
                transform={`rotate(-45 ${x},${y})`}
              >
                {payload.value}
              </text>
            )}
          />
          <YAxis />
          <Tooltip />
          <Legend 
            verticalAlign="top"
            height={36}
          />
          <Bar dataKey="weeklyCallCount" name="Weekly Calls" fill="#3B82F6" />
          <Bar dataKey="monthlyCallCount" name="Monthly Calls" fill="#10B981" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default MonthlyCallSummary;