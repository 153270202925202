export interface CallData {
  participant_a: string;
  participant_b: string;
  timestamp: string; // Add timestamp field
}

export interface CallMatrix {
  [participant_a: string]: {
    [participant_b: string]: string[]; // Array of ISO date strings
  };
}

const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
const API_URL = isLocalhost
  ? 'http://localhost:8788/accountability'
  : 'https://biad-accountability-matrix.pages.dev/accountability';

interface ErrorResponse {
  error: string;
}

async function handleResponse<T>(response: Response): Promise<T> {
  if (!response.ok) {
    const errorData = await response.json() as ErrorResponse;
    throw new Error(errorData.error || 'An error occurred');
  }
  return response.json() as Promise<T>;
}

export async function getCallMatrix(): Promise<CallMatrix> {
  const response = await fetch(API_URL);
  return handleResponse<CallMatrix>(response);
}

export async function logCall(data: Omit<CallData, 'timestamp'>): Promise<void> {
  const callData: CallData = {
    ...data,
    timestamp: new Date().toISOString() // Add current timestamp
  };
  const response = await fetch(API_URL, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(callData),
  });
  await handleResponse<{ message: string }>(response);
}

export async function resetDatabase(): Promise<void> {
  const response = await fetch(`${API_URL}/reset`, {
    method: 'POST',
  });
  await handleResponse<{ message: string }>(response);
}