import React from 'react';
import { names } from '../config';

interface CallPartnerSelectionProps {
  currentUser: string;
  onPartnerSelected: (partner: string) => void;
}

const CallPartnerSelection: React.FC<CallPartnerSelectionProps> = ({ currentUser, onPartnerSelected }) => {
  const partners = names.filter(name => name !== currentUser);

  return (
    <div className="mb-4">
      <h2 className="text-lg font-semibold mb-2">Select Call Partner</h2>
      <div className="grid grid-cols-2 gap-2">
        {partners.map((partner) => (
          <button
            key={partner}
            onClick={() => onPartnerSelected(partner)}
            className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
          >
            {partner}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CallPartnerSelection;